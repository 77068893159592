<template>
    <h1 class="page-header">
        {{ $t( "pages.location.pageHeadline" ) }}
    </h1>

    <p class="body-text">
        {{ $t( "pages.location.paragraph1" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.location.paragraph2" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.location.paragraph3" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.location.paragraph4" ) }}
    </p>

    <div
        class="clickable-img my-4"
        :style="clickableImageStyle"
        @click="followUrlExternal('https://goo.gl/maps/WetJRUiQbT8Y42gg6', '_blank')"
    >
        <img class="img-fluid" src="../assets/images/pages/Google-Maps-20230330.png" alt="Google Maps">
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters( [ 'getThemeOverrides' ] ),
        borderRadius() {
            return this.getThemeOverrides.common.borderRadius
        },
        clickableImageStyle() {
            return { 'borderRadius': this.borderRadius }
        }
    },
    methods: {
        followUrlExternal( url, target ) {
            window.open( url, target )
        }
    }
}
</script>

<style scoped>
.clickable-img {
    cursor: pointer;
    overflow: hidden;
}
</style>